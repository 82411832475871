!(function ($, window, document) {
  function mobileMenuInit() {
    const menu = $('.mobile-menu');
    const body = $('html');
    let state = false;
    function openMobileMenu() {
      body.addClass('mm-active');
      // gsap.to('.mobile-menu_wrapper', {
      //   duration: 0.5, ease: "slow(0.7, 0.7, false)", clipPath: 'path("M0,1010c194,69.6,389.4-40.5,389.4-40.5S592,808.6,800,961.7V0H0V1010z")'
      // });
      // lscroll.destroy();
    }
    function closeMobileMenu() {
      body.removeClass('mm-active');
      // menu.css('clip-path', 'path("M0-23c194,69.6,389.4-40.5,389.4-40.5S592-224.5,800-71.4V-1033H0V-23z")');
      // lscroll.init();
    }

    $('.mobile-menu-trigger').click(function () {
      console.log(state);
      if (state) {
        closeMobileMenu();
      } else {
        openMobileMenu();
      }
      state = !state;
    });
  }

  mobileMenuInit();

  function initLocationForm() {
    // $('.location-overlay').show(), $('body').css('overflow', 'hidden');
    $('.location-overlay')[0].showModal(), $('body').css('overflow', 'hidden');
  }
  function initCustomerSurvey(e) {
    e.stopPropagation(),
      // $('.customer-survey').show(),
      $('#choice_1_1_1').attr('autofocus', ''),
      $('.customer-survey')[0].showModal(),
      $('body').css('overflow', 'hidden'),
      $('body').on('click', checkTarget),
      $('.close-overlay').on('click', closeSurvey);
  }
  function closeSurvey() {
    // $('.customer-survey').hide(),
    $('.customer-survey')[0].close(),
      $('body').css('overflow', 'scroll').off('click', initCustomerSurvey),
      $('.close-overlay').off('click', closeSurvey);
  }
  function checkTarget(e) {
    'customer-survey' === e.target.id && closeSurvey();
  }
  function setCookie(e) {
    if (e.target.value !== Cookies.get('state')) {
      Cookies.remove('state'),
        window.location.hostname.indexOf('.aladdinbailbonds.com') > -1
          ? Cookies.set('state', e.target.value, {
              expires: 7,
              domain: '.aladdinbailbonds.com',
            })
          : Cookies.set('state', e.target.value, {
              expires: 7,
            });
      var locationEntry = $(e.target.selectedOptions).text();
      ga('send', 'event', 'Location Select', 'Navigation', locationEntry),
        location.reload();
    }
  }
  function closeLocationForm() {
    Cookies.set('state', 'skip', {
      expires: 7,
    }),
      $('.choose-location .select2-selection__rendered').text('Select a State'),
      // $('.location-overlay').hide(),
      $('.location-overlay')[0].close(),
      $('body').css('overflow', 'scroll');
  }
  function submitLocation() {
    if ('skip' === $('.location-overlay select[name="state"]').val())
      return (
        $('.location-overlay select[name="state"]').parent().addClass('error'),
        !1
      );
    $('.location-overlay select[name="state"]').parent().removeClass('error'),
      Cookies.set('state', $('.location-overlay select[name="state"]').val(), {
        expires: 7,
      }),
      $('.choose-location .custom-select')
        .val(Cookies.get('state'))
        .trigger('change');
    var stateName = $(
      '.location-overlay select[name="state"] option:selected'
    ).text();
    ga('send', 'event', 'Location Select', 'Interstitial', stateName),
      location.reload(),
      $('.location-overlay').hide(),
      $('body').css('overflow', 'scroll');
  }
  function openForm(e) {
    e.preventDefault(), $('#text-60c25fbfbd268').attr('autofocus', '');
    if (e.target.hasAttribute('open')) {
      $('.contact-form')[0]?.close();
      // $('body').css('overflow', 'scroll');
      // $('.contact-button').removeClass('active');
    } else {
      $('body').css('overflow', 'hidden');
      $('.contact-form')[0]?.showModal();
      $('.contact-button').addClass('active');
    }

    // $('.contact-form, .contact-button').toggleClass('active');
    $('html').toggleClass('contact-form-active');
  }
  $('.contact-form').on('close', function () {
    // $('.contact-form')[0]?.close();
    $('body').css('overflow', 'scroll');
    $('.contact-button').removeClass('active');
  });

  $('.contact-form').on('click', function (e) {
    if (e.target === $('.contact-form')[0]) {
      $('.contact-form')[0].close();
    }
  });
  function disableFaqLinks(e) {
    e.preventDefault();
  }
  function showAnswer(e) {
    $(e.currentTarget).toggleClass('open'),
      $(e.currentTarget).children('.qa-faq-answer').toggle();
  }
  function validateNumber(number) {
    '' !== number.value
      ? ((re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im),
        re.test(number.value)
          ? $(number).parent().removeClass('error')
          : $(number).parent().addClass('error'))
      : $(number).parent().addClass('error');
  }
  function validateInput(input) {
    '' !== input.value
      ? ((re = /^\s+$/i),
        re.test(input.value)
          ? $(input).parent().addClass('error')
          : $(input).parent().removeClass('error'))
      : $(input).parent().addClass('error');
  }
  function validateSpam(input) {
    '' !== input.value
      ? $(input).parent().addClass('error')
      : $(input).parent().removeClass('error');
  }
  function validateSelect(select) {
    '0' === $(select).val()
      ? $(select).parent().addClass('error')
      : $(select).parent().removeClass('error');
  }
  function validateCheckboxes(checkboxes) {
    $(checkboxes).length > 0
      ? $('#fm-item-custom_list-4ed402fe0c3d4 > label').removeClass('error')
      : $('#fm-item-custom_list-4ed402fe0c3d4 > label').addClass('error');
  }
  function validateTreated(radios) {
    $(radios).length > 0
      ? $('#fm-item-custom_list-4ed402ff4a1cc > label').removeClass('error')
      : $('#fm-item-custom_list-4ed402ff4a1cc > label').addClass('error');
  }
  function validateService(radios) {
    $(radios).length > 0
      ? $('#fm-item-custom_list-4ed403007a45a > label').removeClass('error')
      : $('#fm-item-custom_list-4ed403007a45a > label').addClass('error');
  }
  function validateReturn(radios) {
    $(radios).length > 0
      ? $('#fm-item-custom_list-4ed403019b30a > label').removeClass('error')
      : $('#fm-item-custom_list-4ed403019b30a > label').addClass('error');
  }
  function validateRetailOffice(input) {
    '' !== input.value
      ? ((re = /^\s+$/i),
        re.test(input.value)
          ? $('#fm-item-text-4ed40303b254f > label').addClass('error')
          : $('#fm-item-text-4ed40303b254f > label').removeClass('error'))
      : $('#fm-item-text-4ed40303b254f > label').addClass('error');
  }
  function trackClick(e) {
    var buttonName = $(e.currentTarget).text();
    ga('send', 'event', 'Buttons', 'Click', buttonName);
  }
  function _gaLt(e) {
    for (
      var el = e.srcElement || e.target;
      el &&
      ('undefined' == typeof el.tagName ||
        'a' !== el.tagName.toLowerCase() ||
        !el.href);

    )
      el = el.parentNode;
    if (el && el.href) {
      var link = el.href;
      if (link.indexOf(location.host) === -1 && !link.match(/^javascript\:/i)) {
        var target =
            !(!el.target || el.target.match(/^_(self|parent|top)$/i)) &&
            el.target,
          hitBack = function (link, target) {
            target ? window.open(link, target) : (window.location.href = link);
          };
        ga(
          'send',
          'event',
          'Outbound Traffic',
          document.location.hostname,
          link
        ),
          hitBack(link, target),
          e.preventDefault ? e.preventDefault() : (e.returnValue = !1);
      }
    }
  }
  var re,
    stickyNavTop,
    $window = $(window),
    $document = $(document),
    city_page = !1;
  $('.header').length && (stickyNavTop = $('.header').offset().top),
    $window.scroll(function () {
      var $scrollTop = $(window).scrollTop();
      if (window.innerWidth < 1200) {
        $scrollTop > stickyNavTop + 100
          ? $('.header').hasClass('sticky') || $('.header').addClass('sticky')
          : $('.header').removeClass('sticky');
      } else {
        $scrollTop > stickyNavTop
          ? $('.header').hasClass('sticky') || $('.header').addClass('sticky')
          : $('.header').removeClass('sticky');
      }
    });
  var w = window;
  w.addEventListener
    ? w.addEventListener(
        'load',
        function () {
          document.body.addEventListener('click', _gaLt, !1);
        },
        !1
      )
    : w.attachEvent &&
      w.attachEvent('onload', function () {
        document.body.attachEvent('onclick', _gaLt);
      }),
    $document.on('ready', function () {
      var isGa = 'undefined' != typeof ga;
      $('.hero .hero-arrow').length < 1 && $('.hero').addClass('no-arrow'),
        $('.choose-location .custom-select').select2({
          minimumResultsForSearch: 1 / 0,
          // dropdownParent: $('.choose-location'),
        }),
        $('.contact-form').length > 0 &&
          $('.contact-form select').select2({
            minimumResultsForSearch: 1 / 0,
            dropdownParent: $('.contact-form'),
          }),
        $('.customer-survey').hasClass('active') &&
          ($('body').on('click', checkTarget),
          $('.close-overlay').on('click', closeSurvey)),
        $('.section.hero .bg-overlay').length < 1 &&
          $('.section.hero').append('<div class="bg-overlay"></div>'),
        $('.section.testimonial .bg-overlay').length < 1 &&
          $('.section.testimonial').append('<div class="bg-overlay"></div>'),
        $('.section.get-started .bg-overlay').length < 1 &&
          $('.section.get-started').append('<div class="bg-overlay"></div>'),
        void 0 !== Cookies.get('state') || null !== Cookies.get('state')
          ? ($('.choose-location .custom-select')
              .val(Cookies.get('state'))
              .trigger('change'),
            'skip' === Cookies.get('state') &&
              $('.choose-location .select2-selection__rendered').text(
                'Select a State'
              ))
          : $('.choose-location .select2-selection__rendered').text(
              'Select a State'
            ),
        'www.aladdinbailbonds.com' === $(location).attr('hostname')
          ? ((ga_accountid = 'UA-11870371-1'),
            (ga_root_domain = '.aladdinbailbonds.com'))
          : ((ga_accountid = 'UA-40536318-1'), (ga_root_domain = 'none')),
        isGa &&
          (ga('create', ga_accountid, {
            cookieDomain: ga_root_domain,
          }),
          ga('send', 'pageview')),
        $('.choose-location .custom-select').on('change', setCookie),
        $('.contact-button').on('click', openForm),
        $('.qa-faq').on('click', showAnswer),
        $('.qa-faq .qa-faq-title a').on('click', disableFaqLinks),
        $('.cta').length > 0 && $('.cta a').on('click', trackClick),
        $('.cs-trigger, #menu-item-2203, #menu-item-2380').on(
          'click',
          initCustomerSurvey
        ),
        $('#addressSubmit').on('click', function () {
          var cityOrZip = $('#addressInput').val();
          isGa && ga('send', 'event', 'Find Location', 'Search', cityOrZip);
        }),
        $('.contact-options .cta').on('click', function () {
          (city_page = !0),
            isGa && ga('send', 'event', 'Contact Us - City', 'Start');
        }),
        $('.contact-form .submit').attr('onclick', ''),
        $('.customer-survey .submit').attr('onclick', ''),
        $('.contact-form .submit').on('click', function (e) {
          if (
            (validateInput($('input[name="text-562f502d674ae"]')[0]),
            validateNumber($('input[name="text-562f502d6754c"]')[0]),
            validateSelect('select[name="custom_list-562f646de1f5e"]'),
            validateSpam($('input[name="text-565614852bae0"]')[0]),
            !($('.error').length < 1))
          )
            return !1;
          var inquiryType = $(
              'select[name="custom_list-562f504fd8cb8"] option:selected'
            ).text(),
            cityEntry = $('input[name="text-562f502d6769e"]').val(),
            stateEntry = $(
              'select[name="custom_list-562f646de1f5e"] option:selected'
            ).text();
          isGa &&
            (city_page
              ? ga(
                  'send',
                  'event',
                  'Contact Us - City ',
                  'Submit',
                  inquiryType,
                  cityEntry,
                  {
                    dimension1: stateEntry,
                  }
                )
              : ga('send', 'event', 'Contact Us', 'Submit', inquiryType, {
                  dimension1: stateEntry,
                }));
        }),
        $('.customer-survey .submit').on('click', function () {
          return (
            validateCheckboxes(
              '#fm-item-custom_list-4ed402fe0c3d4 .fm-checkbox-list input:checked'
            ),
            validateTreated('#fm-item-custom_list-4ed402ff4a1cc input:checked'),
            validateService('#fm-item-custom_list-4ed403007a45a input:checked'),
            validateReturn('#fm-item-custom_list-4ed403019b30a input:checked'),
            validateRetailOffice($('input[name="text-4ed40303b254f"]')[0]),
            validateSpam($('input[name="text-56561551a468e"]')[0]),
            !!($('.error').length < 1 && isGa) &&
              void ga(
                'send',
                'event',
                'Forms',
                'Submit',
                'Customer Satisfaction Survey'
              )
          );
        }),
        $('.location-overlay').length > 0 &&
          ($('.location-overlay').is(':visible') ||
            (void 0 !== Cookies.get('state') &&
              null !== Cookies.get('state')) ||
            initLocationForm(),
          $('.location-overlay .custom-select').select2({
            minimumResultsForSearch: 1 / 0,
            dropdownParent: $('.location-overlay'),
          }),
          $('.location-overlay button.submit').on('click', submitLocation),
          $('.location-overlay button.skip').on('click', closeLocationForm));
    }),
    $document.ajaxComplete(function (event, request, settings) {
      ('https://www.aladdinbailbonds.com/locations/' !==
        event.currentTarget.baseURI &&
        'http://dev.aladdinbailbonds.devlinux2.digitaria.com/locations/' !==
          event.currentTarget.baseURI) ||
        (console.log('DialogTech Initiated'), _st.render());
    });
})(jQuery, window, document);
